import React from 'react';

import CloseButton from './CloseButton';
import NoScrollBody from './NoScrollBody';

import { OVERLAY_CLOSED } from '../actions/overlay';

import { OverlayDispatch } from '../contexts/overlay';

import { getDisplayName } from '../utils/component';

function withOverlay(WrappedComponent) {
  class WithOverlay extends React.Component {
    constructor(props) {
      super(props);

      this.close = this.close.bind(this);
    }

    close() {
      const dispatch = this.context;

      dispatch({ type: OVERLAY_CLOSED });
    }

    render() {
      return (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-start overflow-auto bg-black bg-opacity-70"
          onClick={(e) => (e.target === e.currentTarget ? this.close() : null)}
        >
          <NoScrollBody />
          <div className="min-w-full min-h-full pt-4 pb-8 flex flex-col bg-white md:min-w-overlay md:min-h-0 md:mt-4 md:pb-0">
            <div className="text-right">
              <CloseButton onClick={this.close} />
            </div>
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  }

  WithOverlay.displayName = `WithOverlay(${getDisplayName(WrappedComponent)})`;
  WithOverlay.contextType = OverlayDispatch;

  return WithOverlay;
}

export default withOverlay;
