import { useBodyClass } from '../hooks';

const NO_SCROLL_CLASSNAME = 'overflow-hidden';

const NoScrollBody = () => {
  useBodyClass([NO_SCROLL_CLASSNAME]);

  return null;
};

export default NoScrollBody;
