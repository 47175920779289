import React from 'react';

import Footer from './Footer';
import Header from './Header';

import overlayReducer from '../reducers/overlay';

import { OverlayDispatch } from '../contexts/overlay';

const defaultOverlay = null;

const Layout = ({ children }) => {
  const [activeOverlay, dispatch] = React.useReducer(
    overlayReducer,
    defaultOverlay
  );
  const isOverlayDisplayed = activeOverlay !== null;

  return (
    <OverlayDispatch.Provider value={dispatch}>
      {isOverlayDisplayed && React.createElement(activeOverlay)}
      <Header />
      <main>{children}</main>
      <Footer />
    </OverlayDispatch.Provider>
  );
};

export default Layout;
