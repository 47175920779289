import React from 'react';

import Nav from './Nav';
import withOverlay from './withOverlay';

import { trackSignUp } from '../utils/tracking';

const MenuOverlay = () => {
  return (
    <>
      <Nav className="flex flex-col text-center" />
      <div className="flex flex-col items-center mt-auto space-y-4">
        <button type="button" className="w-40 h-12 text-lg">
          Login
        </button>
        <button
          type="button"
          className="w-40 h-12 text-lg antialiased font-bold text-white rounded-lg bg-blue"
          onClick={() => trackSignUp('header')}
        >
          Sign Up
        </button>
      </div>
    </>
  );
};

export default withOverlay(MenuOverlay);
