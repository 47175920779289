import React from 'react';

const FooterItem = ({ heading, children }) => {
  return (
    <li className="">
      <h2 className="mb-3 text-2xl font-bold text-beige sm:mb-5 md:text-3xl lg:mb-8 lg:text-4xl">
        {heading}
      </h2>
      <div className="flex flex-col items-start space-y-3 text-lg antialiased font-bold">
        {children}
      </div>
    </li>
  );
};

export default FooterItem;
