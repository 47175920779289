import React from 'react';

import NavLink from './NavLink';

const Nav = (props) => {
  return (
    <nav {...props}>
      <NavLink to="/#why">Why</NavLink>
      <NavLink to="/#pricing">Pricing</NavLink>
      <NavLink to="/#get-started">Get started</NavLink>
      <NavLink to="/help/">Help</NavLink>
    </nav>
  );
};

export default Nav;
