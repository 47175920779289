import React from 'react';
import { Link } from 'gatsby';

import Container from './Container';
import Logo from './Logo';
import FooterItem from './FooterItem';

const Footer = () => {
  return (
    <footer className="bg-black text-gray">
      <Container className="pt-10 pb-4 md:pt-16">
        <div className="ml-1 text-white">
          <Link to="/" className="block w-32 lg:w-36">
            <Logo />
          </Link>
        </div>
        <ul className="my-8 ml-8 space-y-8 sm:w-max sm:mx-auto sm:my-12 sm:flex sm:space-y-0 sm:space-x-16 md:my-16 md:space-x-20 lg:my-20 lg:space-x-24">
          <FooterItem heading="Product">
            <Link to="/#why">Why</Link>
            <Link to="/#pricing">Pricing</Link>
            <Link to="/#get-started">Get started</Link>
          </FooterItem>
          <FooterItem heading="Support">
            <Link to="/help/">Help center</Link>
            <Link to="/help/faq/">FAQ</Link>
            <Link to="/help/contact/">Contact</Link>
          </FooterItem>
          <FooterItem heading="Legal stuff">
            <Link to="/privacy/">Privacy</Link>
            <Link to="/terms/">Terms</Link>
          </FooterItem>
        </ul>
        <p className="antialiased font-bold text-center">
          © 2021 Wolvido. All rights reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
