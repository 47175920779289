import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Container from './Container';
import Logo from './Logo';
import MenuButton from './MenuButton';
import Nav from './Nav';
import MenuOverlay from './MenuOverlay';

import { OVERLAY_OPENED } from '../actions/overlay';

import { OverlayDispatch } from '../contexts/overlay';

import { trackSignUp } from '../utils/tracking';

const Header = () => {
  const dispatch = React.useContext(OverlayDispatch);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          signinUrl
          signupUrl
        }
      }
    }
  `);

  return (
    <header className="sticky top-0 z-30 py-4 bg-beige">
      <Container className="flex items-center justify-between px-1 md:justify-start">
        <Link
          to="/"
          className="block w-40 p-3 opacity-70 lg:w-44 lg:mr-10 lg:p-2"
        >
          <h1>
            <span className="sr-only">Wolvido</span>
            <Logo />
          </h1>
        </Link>
        <MenuButton
          className="md:hidden"
          onClick={() =>
            dispatch({ type: OVERLAY_OPENED, overlay: MenuOverlay })
          }
        />
        <div className="items-center justify-between flex-grow hidden pr-3 md:flex">
          <Nav className="flex" />
          <div>
            <a href={data.site.siteMetadata.signinUrl} className="p-3 text-lg">
              Login
            </a>
            <a
              href={data.site.siteMetadata.signupUrl}
              className="px-8 py-3 ml-3 text-lg antialiased font-bold text-white rounded-lg bg-blue"
              onClick={() => trackSignUp('header')}
            >
              Sign Up
            </a>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
